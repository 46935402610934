import * as React from "react"
import Layout from '../../components/layout/Layout';
import PageNavigator from "../../components/layout/PageNavigator";
import ContentDossier from '../../components/ContentDossiers';
import dossier from '../../data/certificati-professionali/certificati-cqc-merci/dossier.json';

const CertificatiCQCMerci = () => {
    return (
        <Layout>
            <PageNavigator link="/certificati-professionali" text="Certificati professionali" />
            <ContentDossier dossier={dossier} />
        </Layout>
    )
};

export default CertificatiCQCMerci;